






















































































import { defineComponent, reactive } from '@vue/composition-api'
import { useDataTable } from '@/components/composables/useDataTable'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import { Header } from '@/components/composables/useDataTable/types'
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import PublishButton from '@/components/buttons/PublishButton.vue'
import ShowButton from '@/components/buttons/ShowButton.vue'
import { formatDate, formatEvaluationTime } from '@/utils/helpers'
import DashboardService from '@/services/dashboard'
import TenderService from '@/services/tender'
import { CardsDTO, GraphsDTO } from '@/services/dashboard/types'
import HomeStats from '@/views/Dashboard/components/HomeStats.vue'
import { UserRoles } from '@/enums/UserRoles'
import HomeGraphs from '@/views/Dashboard/components/HomeGraphs.vue'
import { ProcurementStatus } from '@/services/myTender/types'

export default defineComponent({
  name: 'Dashboard',
  setup() {
    const searchParams = reactive({
      type: 'buy',
    })
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      DashboardService,
      searchParams,
    )

    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      formatDate,
      formatEvaluationTime,
      DashboardService,
      TenderService,
      searchParams,
      ProcurementStatus,
    }
  },
  components: {
    HomeStats,
    HomeGraphs,
    ShowButton,
    DeleteButton,
    EditButton,
    TenderStatus,
    PublishButton,
  },
  data: function (): {
    stats: CardsDTO[]
    graphs: GraphsDTO[]
  } {
    return {
      stats: [],
      graphs: [],
    }
  },
  computed: {
    isClient(): boolean {
      return this.$store.getters.role === UserRoles.client
    },
    isOperationDirector(): boolean {
      return this.$store.getters.role === UserRoles.operationDirector
    },
    isAdmin(): boolean {
      return this.$store.getters.role === UserRoles.admin
    },
    headers(): Array<Header> {
      return [
        { value: 'name', text: this.$t('tenderName'), sortable: true },
        { value: 'publishDate', text: this.$t('publishDate'), sortable: true },
        { value: 'endDate', text: this.$t('closureDate'), sortable: true },
        { value: 'offerValidityMinutes', text: this.$t('evaluationDuration'), sortable: true },
        { value: 'status', text: this.$t('status'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
  },
  methods: {
    async fetchStatistics(): Promise<void> {
      const { data } = await DashboardService.statistics()
      this.stats = data.data.cards
      this.graphs = data.data.graphics
    },
    fetchActiveTenders(tenderType: string) {
      this.searchParams.type = tenderType
      this.fetchData()
    },
    goToAllTenders() {
      if (this.searchParams.type === 'buy') this.$router.push({ name: 'BuyingTenders' })
      if (this.searchParams.type === 'sell') this.$router.push({ name: 'SellingTenders' })
    },
  },
  watch: {
    totalRecords: function () {
      this.fetchStatistics()
    },
  },
})
