











import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
import VChart from 'vue-echarts'
import { GraphsDTO } from '@/services/dashboard/types'
import Vue from 'vue'
import moment from 'moment'

use([CanvasRenderer, PieChart, BarChart, GridComponent, TitleComponent, TooltipComponent, LegendComponent])

export default Vue.extend({
  name: 'HomeGraphs',
  components: {
    VChart,
  },
  props: {
    graphData: {
      type: Array as () => GraphsDTO[],
      default: [],
    },
  },
  computed: {
    datas(): GraphsDTO[] {
      return this.graphData
    },
    firstGraphOptions(): any { // eslint-disable-line
      return {
        title: {
          text: this.$tc('boughtSoldEnergy'),
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} MW ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: [
            this.datas[0] ? this.$t(this.datas[0].listItems[0].value.p_type) : '',
            this.datas[0] ? this.$t(this.datas[0].listItems[1].value.p_type) : '',
          ],
        },
        series: [
          {
            name: this.$tc('boughtSoldEnergy'),
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              {
                value: this.datas[0] ? this.datas[0].listItems[0].value.sum : 0,
                name: this.datas[0] ? this.$t(this.datas[0].listItems[0].value.p_type) : '',
              },
              {
                value: this.datas[0] ? this.datas[0].listItems[1].value.sum : 0,
                name: this.datas[0] ? this.$t(this.datas[0].listItems[1].value.p_type) : '',
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
    },

    secondGraphOptions(): any { // eslint-disable-line
      return {
        title: {
          text: this.$tc('purchasesSellsPerWeek'),
          left: 'center',
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          data: [this.$tc('buy'), this.$tc('sell')],
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 30,
          },
          data: this.weekDates(),
        },
        yAxis: {
          type: 'value',
          position: 'left',
          axisLabel: {
            formatter: '{value} MW',
          },
        },
        series: [
          {
            name: this.$tc('buy'),
            type: 'bar',
            data: this.datas[1].listItems[0].value,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
          {
            name: this.$tc('sell'),
            type: 'bar',
            data: this.datas[1].listItems[1].value,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
    },
  },
  methods: {
    weekDates() {
      let date = new Date()
      date.setDate(date.getDate() - 29)
      let weeks = []
      for (let i = 1; i < 5; i++) {
        date.setDate(date.getDate() + 1)
        const firstDate = moment(date).format('DD MMM')
        date.setDate(date.getDate() + 6)
        const secondDate = moment(date).format('DD MMM')
        weeks.push(firstDate + '-' + secondDate)
      }
      return weeks
    },
  },
})
