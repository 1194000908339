

















import Vue from 'vue'
import { CardsDTO } from '@/services/dashboard/types'

export default Vue.extend({
  name: 'HomeStats',
  props: {
    statistics: {
      type: Array as () => CardsDTO[],
      default: () => [],
    },
  },
})
